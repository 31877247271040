// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const FORM = document.querySelector(".search-form");

if (FORM) {
    const SOURCES = FORM.querySelectorAll("input[name=source]");

    SOURCES.forEach((source) => {
        source.addEventListener("change", () => {
            FORM.setAttribute("target", source.dataset.target);
        });
    });
}
