// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import Swiper from "swiper";

// init swiper
const HERO_SWIPER = document.querySelector(".swiper-container--slideshow");
if (HERO_SWIPER && HERO_SWIPER.querySelectorAll(".swiper-slide").length > 1) {
    new Swiper (".swiper-container--slideshow", {
        navigation: {
            nextEl: HERO_SWIPER.querySelector(".swiper-button-next"),
            prevEl: HERO_SWIPER.querySelector(".swiper-button-prev"),
        },
        autoplay: {
            delay: 15000,
        },
        loop: true,
        speed: 150,
    });
} else {
    console.log("swiper not initialized");
    console.log(HERO_SWIPER);
}

const BOOK_LISTS = document.querySelectorAll(".book-list");

if (BOOK_LISTS.length > 0) {
    BOOK_LISTS.forEach((book_list) => {
        new Swiper(book_list.querySelector(".swiper-container"), {
            autoplay: {
                delay: 5000,
            },
            breakpoints: {
                480: {
                    slidesPerView: 3,
                },
                640: {
                    slidesPerView: 4,
                },
                768: {
                    slidesPerView: 4,
                },
                1024: {
                    slidesPerView: 5,
                },
            },
            breakpointsInverse: true,
            loop: true,
            navigation: {
                nextEl: book_list.querySelector(".swiper-button--next"),
                prevEl: book_list.querySelector(".swiper-button--prev"),
            },
            slidesPerView: 2,
            spaceBetween: 8,
            speed: 150,
        });
    });
}
