// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import axios from "axios";

const BUTTON = document.querySelector("[data-action=load-more]");

if (BUTTON && l10n["load-more"] !== false) {
    /**
     * Store labels for the button
     */
    const LABELS = {
        default: BUTTON.innerHTML,
        loading: BUTTON.dataset.loadingLabel,
    };

    BUTTON.addEventListener("click", () => {
        /**
         * Mark the button as loading
         */
        BUTTON.innerHTML = LABELS.loading;
        BUTTON.classList.add("is-loading");

        /**
         * FormData must be used with Axios, otherwise
         * POST is sent as JSON-encoded body contents
         */
        const DATA = new FormData();

        DATA.append("action", "load-more");
        DATA.append("current_page", l10n["load-more"].current_page);
        DATA.append("template", JSON.parse(BUTTON.dataset.template));
        DATA.append("query", l10n["load-more"].query);

        /**
         * Send the request
         */
        axios.post(l10n["load-more"].ajax_url, DATA).then((response) => {
            /**
             * Reset the button
             */
            BUTTON.innerHTML = LABELS.default;
            BUTTON.classList.remove("is-loading");

            if (response.data) {
                /**
                 * If a response was found, append it to the target
                 */
                const TARGET = document.querySelector(BUTTON.dataset.target);

                TARGET.innerHTML += response.data;

                /**
                 * Increment to the next page
                 */
                l10n["load-more"].current_page++;

                /**
                 * If no more pages exist, remove the button
                 */
                if (l10n["load-more"].current_page >= l10n["load-more"].max_num_pages) {
                    BUTTON.remove();
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    });
}
